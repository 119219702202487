import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"
import SEO from "../components/seo"
import Hero from "../components/hero"

const ContactInfo = styled.div`
  padding: var(--xl2) 0;
  text-align: center;
  a[href^="tel:"] {
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 900;
    display: block;
    color: var(--black);
  }
  a[href^="mailto:"] {
    display: block;
    margin: var(--medium) 0 var(--medium);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1.125rem;
    color: var(--black);
  }
`

const AddressInfo = styled.div`
  h3 {
    font-size: 1.75rem;
    color: var(--black);
  }
  address {
    text-transform: uppercase;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    display: block;
    font-style: normal;
    font-weight: 500;
    margin: 0 0 var(--medium);
  }
  span {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.3125rem;
    letter-spacing: 0.15em;
    text-transform: uppercase;
  }
  @media (max-width: 640px) {
    padding: 0 var(--medium);
    h3,
    p,
    address,
    span {
      text-align: center;
    }
    h3:first-of-type {
      margin-top: var(--xl2);
    }
  }
`

const FormSection = styled.div`
  background: var(--soft);
  padding: var(--xl2) 0;
`

const Form = styled.div`
  background: var(--heading);
  padding: var(--xl);
  @media (max-width: 640px) {
    padding: var(--medium);
  }
  h3 {
    text-align: center;
    color: var(--white);
    text-transform: uppercase;
  }
  input {
    display: block;
    width: 100%;
    font-size: 1rem;
    padding: var(--small);
    margin: var(--small) 0;
  }
  input::placeholder,
  textarea::placeholder {
    color: var(--black);
    opacity: 1;
  }
  input[type="submit"] {
    -webkit-appearance: none;
    background: var(--button);
    color: var(--white);
    border: 1px solid var(--button);
    font-weight: 700;
    padding: var(--medium);
    cursor: pointer;
  }
  textarea {
    width: 100%;
    font-size: 1rem;
    padding: var(--small);
  }
`

const Contact = () => (
  <Layout>
    <SEO title="Contact" />
    <Hero title="Contact" hero="contact.jpg" />
    <ContactInfo className="container">
      <h2>Me Contacter</h2>
      <a href="tel:0766802398">076 680 23 98</a>
      <a href="mailto:espace.elsa@bluewin.ch">espace.elsa@bluewin.ch</a>
      <p>
        <strong>NOTE:</strong> Si je ne peux répondre immédiatement laissez-moi
        un message combox, SMS possible aussi.
      </p>
    </ContactInfo>
    <FormSection>
      <div className="grid">
        <div className="col-md-1"></div>
        <div className="col-md-5">
          <Form>
            <h3>Formulaire</h3>
            <form
              id="contactform"
              name="contactform"
              method="POST"
              action="https://formsubmit.io/send/2fc1942b-e70b-472b-a277-5e9fb5a3a883"
            >
              <input
                name="_redirect"
                type="hidden"
                id="name"
                value="https://elsamassotherapie.ch/merci"
              />
              <input
                type="text"
                placeholder="NOM / PRÉNOM*"
                id="nom"
                name="Nom"
                required={true}
              />
              <input
                type="email"
                placeholder="E-MAIL*"
                id="email"
                name="Email"
                required={true}
              />
              <input
                type="tel"
                placeholder="TÉLÉPHONE*"
                id="telephone"
                name="Téléphone"
                required={true}
              />
              <textarea
                placeholder="MESSAGE*"
                id="message"
                name="Message"
                required={true}
                rows={5}
              />
              <input
                name="_formsubmit_id"
                type="text"
                style={{ display: "none" }}
              />
              <input type="submit" value="ENVOYER" />
            </form>
          </Form>
        </div>
        <div className="col-md-1"></div>
        <div className="col-md-4">
          <AddressInfo className="vertical-center">
            <h3>Adresse</h3>
            <p>Le espace est situe au cœur de la ville de Lausanne</p>
            <address>
              Rue Mauborget 1, 2ème étage
              <br />
              1003 Lausanne
            </address>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2745.2483631557316!2d6.6273589162612865!3d46.52298697018986!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478c2e2e1160141b%3A0x43b7f8212c4cafc3!2sRue%20Mauborget%201%2F2%C3%A8me%20%C3%A9tage%2C%201003%20Lausanne%2C%20Switzerland!5e0!3m2!1sen!2spt!4v1591975917717!5m2!1sen!2spt"
              width="100%"
              height="250"
              title="Google Map"
              frameBorder="0"
              style={{ border: 0, marginBottom: "var(--medium)" }}
              allowFullScreen={false}
              aria-hidden={false}
            ></iframe>
            <h3 style={{ paddingTop: "var(--medium)" }}>Horaires</h3>
            <span>Sur Rendez-vous</span>
          </AddressInfo>
        </div>
        <div className="col-md-1"></div>
      </div>
    </FormSection>
  </Layout>
)

export default Contact
